function find  (path, startAt = [], parent = null) 
{
  let __selected = null;
  const __items = startAt;
  __items.forEach(
    function (item) {
      if (__selected) return;
      item.parent = parent;

      const __to_array = item.to.split("/");
      const __path_array = path.split("/");
      let __found = __to_array.length === __path_array.length;
      for (var i = 0; __found && i < __path_array.length; i++) {
        __found &= (__path_array[i] === __to_array[i]) || (__to_array[i][0]===":");
      }

      if (__found) {
        __selected = item;
        return;
      }
      if (item.contains && item.contains.items) {
        __selected = find(path, item.contains.items, item);
      }
    }
  )
  
  return __selected;
} 

function breadcrumbs(path, data)
{
    const result = [];
    var __result = find(path, data);
    while (__result) {
      result.push({
          to: __result.to,
          text: __result.caption
      });
      __result = __result.parent;
  }
  return result;
}
export { find };
    
export default breadcrumbs; 