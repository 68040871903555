import api from "../utils/api";
import { send } from "./alert";


export const base64 = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/files/me/download/s3/b/u/${uuid}`);
        return res.data;
    } catch (err) {
        send(`Error retrieving file. ${err}`, "error");
    }
};

export const remove = (uuid) => async (dispatch) => {
    try {
        const res = await api.delete(`/files/me/remove/s3/u/${uuid}`);
        return res;
    } catch (err) {
        send(`Error retrieving file. ${err}`, "error");
    }
}