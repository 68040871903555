import React from "react";
import Col from "./colgroup.col";

class FeraColGroup extends React.Component {
    render() {
        return (
            <colgroup>
                {this.props.headers.map((item, index) => {
                    return (
                        <Col key={`cgi${index}`} item={item} index={index} />);
                })}
            </colgroup>
        );
    }
}


export default FeraColGroup;