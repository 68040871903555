import React from "react";
import { connect } from "react-redux";
import {  Card, Col, Row } from 'react-bootstrap';
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { isGuid } from "../../../utils/string";
import { url } from "../../../utils/api";

import ConditionalButton from "../../../layout/conditional-button";
import ConditionalDropdown from "../../../layout/conditional-dropdown";
import Dropzone from "../../../layout/dropzone";
import Folder from "../../../layout/folder";

import Items from "./items";
import { update as saveFolder, contents as getFiles } from "../../../actions/folder";
import { base64 as downloadS3, remove as destroyS3 } from "../../../actions/s3";
import { archive, unarchive,  } from "../../../actions/file";
import Modal from "../../../layout/modal";

class Files extends React.Component {
    constructor(params) {
        super(params);

        this.state = {
            path: "S3:\\",
            level: 1,
            permissions: {
                folder: {
                    create: false,
                    delete: false,
                    update: false
                },
                selected: {
                    archive: false,
                    destroy: false,
                    share: false
                }
            },
            modal: {
                folder: {
                    show: false
                },
                destroy: {
                    show: false
                },
                error: {
                    show: false
                }
            },
            contents: [],
            selected: []
        }
    }


    componentDidMount = async () => {
        this.fetchData();
    }

    ///upload/r/:referenceUuid/p/:permissions
    fetchData = async () => {
        const __data = await this.props.getFiles({ referenceUuid: this.props.client, path: this.state.path });

        this.setState({
            ...this.state,
            contents: [...__data],
            permissions: {
                ...this.state.permissions,
                selected: {
                    archive: false,
                    destroy: false,
                    share: false
                }
            }
        });
    }

    get isRoot() {
        return this.state.path === "S3:\\";
    }

    get selected() {
        const result = [];
        result.push({ onClick: this.onDownloadFiles, caption: "Download" });
        if (this.state.permissions.selected.archive)
            result.push({ onClick: this.onArchiveFiles, caption: "Archive" });
        if (this.state.permissions.selected.destroy)
            result.push({ onClick: this.onDestroyFiles, caption: "Destroy" });
        return result;
    }

    get selectedUuids() {
        const __selected = this.state.selected;
        const __uuids = [];
        for (let i = 0; i < __selected.length; i++) {
            __uuids.push(__selected[i]);
        }
        return __uuids;
    }

    checkFileShares({ item, minimum }) {
        let result = item.FileShares.length;
        for (let j = 0; j < item.FileShares.length; j++) {
            const __share = item.FileShares[j];
            result &= __share.flags >= minimum;
            if (!result) break;
        }
        return result;
    }

    checkShareFlags({ selected, minimum }) {
        let result = selected.length;
        for (let i = 0; i < selected.length; i++) {
            result = this.checkFileShares({ item: selected[i], minimum });
            if (!result) break;
        }
        return result;
    }

    onDownloadFiles = async () => {

    }

    onArchiveFiles = async () => {
        await this.props.archive(this.selectedUuids, 1);
        await this.fetchData();
    }

    onDestroyFiles = async (e) => {
        e.preventDefault();
        const __selected = this.state.selected;
        let __directory_not_empty = false;
        for (let i = 0; i < __selected.length; i++) {
            const uuid = __selected[i].uuid;
            const __item = this.state.contents.find(item => ((item.uuid === uuid) && (item.contentType === "directory")));
            if (!__item) continue;
            const __data = await this.props.getFiles({ referenceUuid: this.props.client, path: __item.path });
            __directory_not_empty = (__data?.length > 0);
            if (__directory_not_empty) break;
        }

        if (__directory_not_empty) {
            this.setState({
                ...this.state,
                modal: {
                    ...this.state.modal,
                    error: {
                        show: true,
                        title: 'Error',
                        body: `The selection contains a folder that is not empty. Please empty the folder before attempting to delete. Click ok to return to editor.`,
                        data: { ...e }
                    }
                }
            });

            return;
        }
        
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                destroy: {
                    show: true,
                    title: 'Confirm destruction',
                    body: `Confirm destruction of these files. This will permanently remove these files from storage. This action cannot be undone. Click ok to continue or cancel to return to editor.`,
                    data: { ...e }
                }
            }
        })
    }

    onDestroyHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                destroy: {
                    ...this.state.modal.destroy,
                    show: false
                }
            }
        })
    }

    onDestroyOk = async (e) => {
        this.onDestroyHideModal(e);
        const __selected = this.state.selected;

        for (let i = 0; i < __selected.length; i++) {
            const item = __selected[i];
            await this.props.destroyS3(item.uuid);
        }
        await this.fetchData();
        
    }

    onErrorOk = async (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                error: {
                    ...this.state.modal.error,
                    show: false
                }
            }
        })        
    }

    onFolderOk = async (e) => {
        this.onFolderHideModal(e);
        const __folder = await this.props.saveFolder
            (
                {
                    referenceUuid: this.props.client,
                    ownerUuid: this.props.auth.user.uuid,
                    path: `${this.state.path.replace("S3:", "")}${e.name}\\`,
                    uuid: e.uuid,
                    shares:
                        [
                            {
                                fileUuid: e.uuid,
                                personUuid: this.props.auth.user.uuid,
                                flags: 4
                            }
                        ],
                }
            );
        
        await this.fetchData();
    }
    
    onFolderHideModal = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                folder: {
                    ...this.state.modal.folder,
                    show: false
                }
            }
        })
    }

    onFolderCreate = (e) => {
        this.setState({
            ...this.state,
            modal: {
                ...this.state.modal,
                folder: {
                    ...this.state.modal.folder,
                    show: true
                }
            }
        })
    }

    onCheckedItem = async (options) => {
        const __selected = this.state.selected;
        const idx = __selected.findIndex(item => item.uuid === options.uuid);

        if (idx === -1) {
            __selected.push(options);
        } else {
            __selected.splice(idx, 1);
        }

        this.setState({
            ...this.state,
            selected: [
                ...__selected
            ],
            permissions: {
                ...this.state.permissions,
                selected: {
                    archive: this.checkShareFlags({ selected: __selected, minimum: 2 }),
                    destroy: this.checkShareFlags({ selected: __selected, minimum: 4 }),
                    share: this.checkShareFlags({ selected: __selected, minimum: 4 })
                }
            }
        });
    }

    onOpenItem = async (options) => {
        if (options.extension === "__dir") {
            this.setState({
                ...this.state,
                path: options.path,
                selected: [],
                permissions: {
                    folder: {
                        create: this.checkFileShares({ item: options, minimum: 4 }),
                        delete: this.checkFileShares({ item: options, minimum: 4 }),
                        update: this.checkFileShares({ item: options, minimum: 2 }),
                    },
                    selected: {
                        archive: false,
                        destroy: false,
                        share: false
                    }
                }
            }, this.fetchData)
        } else {
            const _result = await this.props.downloadS3(options.uuid);
            const linkSource = `data:${options.contentType};base64,${_result.data}`;
            const link = document.createElement("a");

            link.href = linkSource;
            link.setAttribute(
                'download',
                options.name,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }

    onPathUp = () => {
        const __array = this.state.path.split("\\");
        __array.pop();
        __array.pop();
        const __path = __array.join("\\");
        this.setState({
                ...this.state,
                path: __path +"\\"
        }, this.fetchData)
    }

    onFileUpload = async (e) => {
        await this.fetchData();
    }

    render() {
        return (
            <>
                <Row>
                    { (!this.isRoot && this.state?.permissions?.folder.update ? (
                    <Col xs="12" xxl="4">
                        <Dropzone label="Upload file" autoRemove onFileUpload={ this.onFileUpload } url={`files/me/upload/s3/f?path=${this.state.path}`} />
                    </Col>
                    ) : '')}
                    <Col xs="12" xxl={ this.state?.permissions?.folder.update ? 8 : 12 }>
                        <Row>
                            <Col xs="6">
                                <h2 style={{ height: "var(--small-title-height)", color: "var(--primary)", marginTop: "5px" }}>{this.state.path.replace("S3:", "") === "\\" ? "[Home]" : this.state.path.replace("S3:", "")}
                                </h2>
                            </Col>
                            <Col xs="6" style={{ textAlign: "right" }}>
                                <ConditionalButton enabledVariant="outline-primary" display={ !this.isRoot } enabled onEnabledClick={this.onPathUp} icon="arrow-top-left"></ConditionalButton>
                                <ConditionalButton enabledVariant="outline-primary" display={ !this.isRoot && this.state?.permissions?.folder.update } enabled onEnabledClick={this.onFolderCreate} icon="folder">Create Folder</ConditionalButton>
                                <ConditionalDropdown display={ this.state.permissions.selected.archive } className="ms-sm-1" enabledVariant="outline-primary" disabledVariant="secondary" enabled={ this.state.selected.length >= 1 } items={this.selected}>Selected</ConditionalDropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Items selected={ this.state.selected } index="uuid" files={this.state.contents} onOpen={this.onOpenItem} onChecked={ this.onCheckedItem } />                            
                        </Row>
                    </Col>
                </Row>

                <Folder
                    show={this.state.modal.folder.show}
                    date={this.state.modal.folder.name || ""}
                    path={this.state.path}
                    uuid={this.state.modal.folder.uuid}
                    onCancel={this.onFolderHideModal}
                    onOk={this.onFolderOk}
                />
                
                <Modal
                    show={this.state.modal.destroy.show}
                    title={this.state.modal.destroy.title}
                    body={this.state.modal.destroy.body}
                    buttons={[{
                        default: true,
                        caption: "cancel",
                        onClick: this.onDestroyHideModal,
                        variant: "secondary"
                    },
                    {
                        caption: "ok",
                        onClick: this.onDestroyOk,
                        variant: "outline-primary"
                    }]}
                /> 
                
                <Modal
                    show={this.state.modal.error.show}
                    title={this.state.modal.error.title}
                    body={this.state.modal.error.body}
                    buttons={[
                        {
                            caption: "ok",
                            onClick: this.onErrorOk,
                            variant: "outline-primary"
                        }]}
                /> 
            </>
        )
    }
}

Files.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
    });
};

export default connect(mapStateToProps, { saveFolder, getFiles, downloadS3, archive, unarchive, destroyS3 })(Files);
