import React from "react";
import DatePicker from 'react-datepicker';

import { toDate } from "../../utils/date";

class DatePickerEx extends React.Component {
    render() {
        const __date = this.props.selected;
        return (
            <DatePicker {...this.props} selected={toDate(__date)} />
        )
    }
}

export default DatePickerEx;