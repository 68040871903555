import React from "react";

class FeraTheadTh extends React.Component
{

    get sortingClassName() {
        if (!this.props.sort.active) return "";
        return `sorting_${this.props.sort.direction === "DESC" ? "desc" : ""}${this.props.sort.direction === "ASC" ? "asc" : ""}`;
    }

    render() {
        return (
            <th
                key={this.props.item.key}
                colSpan={this.props.item.colspan || 1}
                rowSpan={this.props.item.rowspan || 1}
                role="columnheader"
                title={this.props.item.title || (this.props.item.sortable ? "Apply Sort" : "")}
                className={`${this.props.item.className}${this.props.item.sortable ?? " sorting"} ${this.sortingClassName}`}
                style={{ cursor: `${this.props.item.sortable ? "pointer" : "default"}` }}
                data-column={this.props.item}
                onClick={(e) => { if (this.props.onColumnClick) this.props.onColumnClick(this.props.item) }}
            >
                {this.props.item.header}
            </th>
        )
    }
}

export default FeraTheadTh;