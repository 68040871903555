import {
  LOGIN_SUCCESS,
  AUTH_ERROR,
  LOGOUT,
  USER_LOADED,
  SET_LOADING,
  SET_LOADED,
  PASSWORD_CHANGE,
  RESET_SUCCESS,
  RESET_FAILED,
  VERIFY_TOKEN_FAILED,
  VERIFY_TOKEN_SUCCESS
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  authenticated: null,
  loading: true,
  user: null,
  settings: {},
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADED:
      return {
        ...state,
        loading: false,
      };
    case USER_LOADED:
      return {
        ...state,
        authenticated: true,
        loading: false,
        user: payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        authenticated: true,
        loading: false,
      };
    case PASSWORD_CHANGE:
      return {
        ...state,
        ...payload,
        authenticated: true,
        loading: false,
      };
    case RESET_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        next: "login"
      };
    case VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        forward: false,
        next: null
      };
    case RESET_FAILED:
      return {
        ...state,
        ...payload,
        loading: false,
        next: null
      };
    case VERIFY_TOKEN_FAILED:
      return {
        ...state,
        ...payload,
        loading: false,
        token: null,
        next: "forgot" 
      };
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        authenticated: false,
        loading: false,
        user: null,
      };
    default:
      return state;
  }
}

export default authReducer;
