import React from "react";
import { Button, Modal } from 'react-bootstrap';

class ExModal extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    get buttons() {
        return this.props.buttons.map((item, index) => {
            return <Button key={`modal_button_${index}`} variant={item.variant || "secondary"} onClick={item.onClick}>{ item.caption }</Button>
        });
    }

    onHideModal = () => {
        const __default_items = this.props.buttons.filter(item => item.default);
        __default_items.forEach((item) => {
            if (item.onClick) item.onClick();
        });
        this.setState({
            show: false
        });
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.body}</Modal.Body>
                <Modal.Footer>
                    {this.buttons}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ExModal;