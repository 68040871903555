import api from "../utils/api";

import {
  LOGOUT, GET_FILES, ADD_FILE
} from "./types";

export const list = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/files/m/${uuid}`);

        if (res?.data) {
            dispatch({
                type: GET_FILES,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const add = (file) => async (dispatch) => {
    dispatch({
        type: ADD_FILE,
        payload: file
    });
}

export const unzip = (uuid) => async (dispatch) => {
    try {
        const res = await api.post("/files/unzip", { uuid });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const clean = (uuid) => async (dispatch) => {
    try {
        const res = await api.delete(`/files/r/i/${uuid}`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    } 
}


export const base64 = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/files/r/as/i/${uuid}/s/base64`);
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const archive = (uuids, mask) => async (dispatch) => {
    try {
        const res = await api.post(`/files/me/archive/a/1/m/${mask}`, { uuids });
        return res.data;
    } catch (err) {
        console.log(err);
    } 
}

export const unarchive = (uuids, mask) => async (dispatch) => {
    try {
        const res = await api.post(`/files/me/archive/a/0/m/${mask}`, { uuids });
        return res.data;
    } catch (err) {
        console.log(err);
    } 
}

export const download = ({ uuids }) => async (dispatch) => {
    try {
        const res = await api.post(`/files/me/download/s3`, { uuids });
        return res.data;
    } catch (err) {
        console.log(err);
    } 
}