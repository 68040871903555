import React from "react";
import Th from "./thead.th";

class FeraTheadTr extends React.Component {
    render() {
        return (
            <tr>
                {this.props.headers.map((item, index) => {
                    return (
                        <Th key={`thead.th.${index}`} item={item} sort={{ active: this.props.sort?.index === index, direction: this.props.sort.direction }} onColumnClick={this.props.onColumnClick} />
                    );
                })}
            </tr>
        )
    }
}

export default FeraTheadTr;