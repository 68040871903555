import React from "react";
import Item from "./item";

class Items extends React.Component {

    render() {
        return (
            <>
                {this.props.files?.map((item, index) => {
                    return (
                        <Item key={`file_item_idx${index}`} {...item} checked={ this.props.selected?.findIndex(option => option.uuid === item.uuid) >= 0 } onOpen={ this.props.onOpen } onChecked={ this.props.onChecked } />
                    );
                })}
            </>
        )
    }
}

export default Items;