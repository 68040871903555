import React from "react";
import { NavLink } from "react-router-dom";

import SidebarMenuItems from "./items";
import ItemLabel from "./label";

class item extends React.Component {
    constructor(props) {
        super(props);
    }

    get page() {
        return this.props.item;
    }

    get pathname() {
        return window.location.pathname;
    }
    
    get active() {
        return this.page.to.startsWith('#') ? false : this.pathname === this.page.to || this.pathname.indexOf(`${this.page.to}/`) > -1;
    }

    render() {

        if (this.page.contains && !this.page.contains?.hide) {
            return (
                <li>
                    <NavLink to={this.page.to} data-bs-target={this.page.to}>
                        <ItemLabel icon={this.page.icon} caption={this.page.title} />
                    </NavLink>
                    <ul>
                        <SidebarMenuItems items={this.page.contains} />
                    </ul>
                </li>
            );
        }
        if (this.page.target) {
            return (
                <li key={this.page.id}>
                    <a href={this.page.to} target={this.page.target} rel="noopener noreferrer">
                        <ItemLabel icon={this.page.icon} caption={this.page.title} />
                    </a>
                </li>
            );
        }
        return (
            <li>
                <NavLink to={this.page.to} activeClassName="">
                    <ItemLabel icon={this.page.icon} caption={this.page.title} />
                </NavLink>
            </li>
        );
    }
};

export default item;
