
import React from "react";
import Select from "react-select";
import { deepEqual } from "../../../utils/object";

export function exists(value) {
    return CountryArray.filter(obj => { return obj.Abbr === value });
}

export const CountryArray = [
  { Abbr: "AF", Full: "Afghanistan" },
  { Abbr: "AL", Full: "Albania" },
  { Abbr: "DZ", Full: "Algeria" },
  { Abbr: "AS", Full: "American Samoa" },
  { Abbr: "AD", Full: "Andorra" },
  { Abbr: "AO", Full: "Angola" },
  { Abbr: "AI", Full: "Anguilla" },
  { Abbr: "AQ", Full: "Antarctica" },
  { Abbr: "AG", Full: "Antigua and Barbuda" },
  { Abbr: "AR", Full: "Argentina" },
  { Abbr: "AM", Full: "Armenia" },
  { Abbr: "AW", Full: "Aruba" },
  { Abbr: "AU", Full: "Australia" },
  { Abbr: "AT", Full: "Austria" },
  { Abbr: "AZ", Full: "Azerbaijan" },
  { Abbr: "BS", Full: "Bahamas (the)" },
  { Abbr: "BH", Full: "Bahrain" },
  { Abbr: "BD", Full: "Bangladesh" },
  { Abbr: "BB", Full: "Barbados" },
  { Abbr: "BY", Full: "Belarus" },
  { Abbr: "BE", Full: "Belgium" },
  { Abbr: "BZ", Full: "Belize" },
  { Abbr: "BJ", Full: "Benin" },
  { Abbr: "BM", Full: "Bermuda" },
  { Abbr: "BT", Full: "Bhutan" },
  { Abbr: "BO", Full: "Bolivia" },
  { Abbr: "BQ", Full: "Bonaire, Sint Eustatius and Saba" },
  { Abbr: "BA", Full: "Bosnia and Herzegovina" },
  { Abbr: "BW", Full: "Botswana" },
  { Abbr: "BV", Full: "Bouvet Island" },
  { Abbr: "BR", Full: "Brazil" },
  { Abbr: "IO", Full: "British Indian Ocean Territory" },
  { Abbr: "BN", Full: "Brunei Darussalam" },
  { Abbr: "BG", Full: "Bulgaria" },
  { Abbr: "BF", Full: "Burkina Faso" },
  { Abbr: "BI", Full: "Burundi" },
  { Abbr: "CV", Full: "Cabo Verde" },
  { Abbr: "KH", Full: "Cambodia" },
  { Abbr: "CM", Full: "Cameroon" },
  { Abbr: "CA", Full: "Canada" },
  { Abbr: "KY", Full: "Cayman Islands" },
  { Abbr: "CF", Full: "Central African Republic" },
  { Abbr: "TD", Full: "Chad" },
  { Abbr: "CL", Full: "Chile" },
  { Abbr: "CN", Full: "China" },
  { Abbr: "CX", Full: "Christmas Island" },
  { Abbr: "CC", Full: "Cocos (Keeling) Islands" },
  { Abbr: "CO", Full: "Colombia" },
  { Abbr: "KM", Full: "Comoros" },
  { Abbr: "CD", Full: "Congo (Democratic Republic of the)" },
  { Abbr: "CG", Full: "Congo" },
  { Abbr: "CK", Full: "Cook Islands" },
  { Abbr: "CR", Full: "Costa Rica" },
  { Abbr: "HR", Full: "Croatia" },
  { Abbr: "CU", Full: "Cuba" },
  { Abbr: "CW", Full: "Curaçao" },
  { Abbr: "CY", Full: "Cyprus" },
  { Abbr: "CZ", Full: "Czechia" },
  { Abbr: "CI", Full: "Côte d'Ivoire" },
  { Abbr: "DK", Full: "Denmark" },
  { Abbr: "DJ", Full: "Djibouti" },
  { Abbr: "DM", Full: "Dominica" },
  { Abbr: "DO", Full: "Dominican Republic (the)" },
  { Abbr: "EC", Full: "Ecuador" },
  { Abbr: "EG", Full: "Egypt" },
  { Abbr: "SV", Full: "El Salvador" },
  { Abbr: "GQ", Full: "Equatorial Guinea" },
  { Abbr: "ER", Full: "Eritrea" },
  { Abbr: "EE", Full: "Estonia" },
  { Abbr: "SZ", Full: "Eswatini" },
  { Abbr: "ET", Full: "Ethiopia" },
  { Abbr: "FK", Full: "Falkland Islands (the) [Malvinas]" },
  { Abbr: "FO", Full: "Faroe Islands (the)" },
  { Abbr: "FJ", Full: "Fiji" },
  { Abbr: "FI", Full: "Finland" },
  { Abbr: "FR", Full: "France" },
  { Abbr: "GF", Full: "French Guiana" },
  { Abbr: "PF", Full: "French Polynesia" },
  { Abbr: "TF", Full: "French Southern Territories (the)" },
  { Abbr: "GA", Full: "Gabon" },
  { Abbr: "GM", Full: "Gambia (the)" },
  { Abbr: "GE", Full: "Georgia" },
  { Abbr: "DE", Full: "Germany" },
  { Abbr: "GH", Full: "Ghana" },
  { Abbr: "GI", Full: "Gibraltar" },
  { Abbr: "GR", Full: "Greece" },
  { Abbr: "GL", Full: "Greenland" },
  { Abbr: "GD", Full: "Grenada" },
  { Abbr: "GP", Full: "Guadeloupe" },
  { Abbr: "GU", Full: "Guam" },
  { Abbr: "GT", Full: "Guatemala" },
  { Abbr: "GG", Full: "Guernsey" },
  { Abbr: "GN", Full: "Guinea" },
  { Abbr: "GW", Full: "Guinea-Bissau" },
  { Abbr: "GY", Full: "Guyana" },
  { Abbr: "HT", Full: "Haiti" },
  { Abbr: "HM", Full: "Heard Island and McDonald Islands" },
  { Abbr: "VA", Full: "Holy See (the)" },
  { Abbr: "HN", Full: "Honduras" },
  { Abbr: "HK", Full: "Hong Kong" },
  { Abbr: "HU", Full: "Hungary" },
  { Abbr: "IS", Full: "Iceland" },
  { Abbr: "IN", Full: "India" },
  { Abbr: "ID", Full: "Indonesia" },
  { Abbr: "IR", Full: "Iran (Islamic Republic of)" },
  { Abbr: "IQ", Full: "Iraq" },
  { Abbr: "IE", Full: "Ireland" },
  { Abbr: "IM", Full: "Isle of Man" },
  { Abbr: "IL", Full: "Israel" },
  { Abbr: "IT", Full: "Italy" },
  { Abbr: "JM", Full: "Jamaica" },
  { Abbr: "JP", Full: "Japan" },
  { Abbr: "JE", Full: "Jersey" },
  { Abbr: "JO", Full: "Jordan" },
  { Abbr: "KZ", Full: "Kazakhstan" },
  { Abbr: "KE", Full: "Kenya" },
  { Abbr: "KI", Full: "Kiribati" },
  { Abbr: "KP", Full: "Korea (the Democratic People's Republic of)" },
  { Abbr: "KR", Full: "Korea (the Republic of)" },
  { Abbr: "KW", Full: "Kuwait" },
  { Abbr: "KG", Full: "Kyrgyzstan" },
  { Abbr: "LA", Full: "Lao People's Democratic Republic (the)" },
  { Abbr: "LV", Full: "Latvia" },
  { Abbr: "LB", Full: "Lebanon" },
  { Abbr: "LS", Full: "Lesotho" },
  { Abbr: "LR", Full: "Liberia" },
  { Abbr: "LY", Full: "Libya" },
  { Abbr: "LI", Full: "Liechtenstein" },
  { Abbr: "LT", Full: "Lithuania" },
  { Abbr: "LU", Full: "Luxembourg" },
  { Abbr: "MO", Full: "Macao" },
  { Abbr: "MG", Full: "Madagascar" },
  { Abbr: "MW", Full: "Malawi" },
  { Abbr: "MY", Full: "Malaysia" },
  { Abbr: "MV", Full: "Maldives" },
  { Abbr: "ML", Full: "Mali" },
  { Abbr: "MT", Full: "Malta" },
  { Abbr: "MH", Full: "Marshall Islands" },
  { Abbr: "MQ", Full: "Martinique" },
  { Abbr: "MR", Full: "Mauritania" },
  { Abbr: "MU", Full: "Mauritius" },
  { Abbr: "YT", Full: "Mayotte" },
  { Abbr: "MX", Full: "Mexico" },
  { Abbr: "FM", Full: "Micronesia" },
  { Abbr: "MD", Full: "Moldova" },
  { Abbr: "MC", Full: "Monaco" },
  { Abbr: "MN", Full: "Mongolia" },
  { Abbr: "ME", Full: "Montenegro" },
  { Abbr: "MS", Full: "Montserrat" },
  { Abbr: "MA", Full: "Morocco" },
  { Abbr: "MZ", Full: "Mozambique" },
  { Abbr: "MM", Full: "Myanmar" },
  { Abbr: "NA", Full: "Namibia" },
  { Abbr: "NR", Full: "Nauru" },
  { Abbr: "NP", Full: "Nepal" },
  { Abbr: "NL", Full: "Netherlands" },
  { Abbr: "NC", Full: "New Caledonia" },
  { Abbr: "NZ", Full: "New Zealand" },
  { Abbr: "NI", Full: "Nicaragua" },
  { Abbr: "NE", Full: "Niger (the)" },
  { Abbr: "NG", Full: "Nigeria" },
  { Abbr: "NU", Full: "Niue" },
  { Abbr: "NF", Full: "Norfolk Island" },
  { Abbr: "MP", Full: "Northern Mariana Islands" },
  { Abbr: "NO", Full: "Norway" },
  { Abbr: "OM", Full: "Oman" },
  { Abbr: "PK", Full: "Pakistan" },
  { Abbr: "PW", Full: "Palau" },
  { Abbr: "PS", Full: "Palestine" },
  { Abbr: "PA", Full: "Panama" },
  { Abbr: "PG", Full: "Papua New Guinea" },
  { Abbr: "PY", Full: "Paraguay" },
  { Abbr: "PE", Full: "Peru" },
  { Abbr: "PH", Full: "Philippines" },
  { Abbr: "PN", Full: "Pitcairn" },
  { Abbr: "PL", Full: "Poland" },
  { Abbr: "PT", Full: "Portugal" },
  { Abbr: "PR", Full: "Puerto Rico" },
  { Abbr: "QA", Full: "Qatar" },
  { Abbr: "MK", Full: "Republic of North Macedonia" },
  { Abbr: "RO", Full: "Romania" },
  { Abbr: "RU", Full: "Russian Federation" },
  { Abbr: "RW", Full: "Rwanda" },
  { Abbr: "RE", Full: "Réunion" },
  { Abbr: "BL", Full: "Saint Barthélemy" },
  { Abbr: "SH", Full: "Saint Helena, Ascension and Tristan da Cunha" },
  { Abbr: "KN", Full: "Saint Kitts and Nevis" },
  { Abbr: "LC", Full: "Saint Lucia" },
  { Abbr: "MF", Full: "Saint Martin (French)" },
  { Abbr: "PM", Full: "Saint Pierre and Miquelon" },
  { Abbr: "VC", Full: "Saint Vincent and the Grenadines" },
  { Abbr: "WS", Full: "Samoa" },
  { Abbr: "SM", Full: "San Marino" },
  { Abbr: "ST", Full: "Sao Tome and Principe" },
  { Abbr: "SA", Full: "Saudi Arabia" },
  { Abbr: "SN", Full: "Senegal" },
  { Abbr: "RS", Full: "Serbia" },
  { Abbr: "SC", Full: "Seychelles" },
  { Abbr: "SL", Full: "Sierra Leone" },
  { Abbr: "SG", Full: "Singapore" },
  { Abbr: "SX", Full: "Sint Maarten (Dutch)" },
  { Abbr: "SK", Full: "Slovakia" },
  { Abbr: "SI", Full: "Slovenia" },
  { Abbr: "SB", Full: "Solomon Islands" },
  { Abbr: "SO", Full: "Somalia" },
  { Abbr: "ZA", Full: "South Africa" },
  { Abbr: "GS", Full: "South Georgia and the South Sandwich Islands" },
  { Abbr: "SS", Full: "South Sudan" },
  { Abbr: "ES", Full: "Spain" },
  { Abbr: "LK", Full: "Sri Lanka" },
  { Abbr: "SD", Full: "Sudan" },
  { Abbr: "SR", Full: "Suriname" },
  { Abbr: "SJ", Full: "Svalbard and Jan Mayen" },
  { Abbr: "SE", Full: "Sweden" },
  { Abbr: "CH", Full: "Switzerland" },
  { Abbr: "SY", Full: "Syrian Arab Republic" },
  { Abbr: "TW", Full: "Taiwan" },
  { Abbr: "TJ", Full: "Tajikistan" },
  { Abbr: "TZ", Full: "Tanzania" },
  { Abbr: "TH", Full: "Thailand" },
  { Abbr: "TL", Full: "Timor-Leste" },
  { Abbr: "TG", Full: "Togo" },
  { Abbr: "TK", Full: "Tokelau" },
  { Abbr: "TO", Full: "Tonga" },
  { Abbr: "TT", Full: "Trinidad and Tobago" },
  { Abbr: "TN", Full: "Tunisia" },
  { Abbr: "TR", Full: "Turkey" },
  { Abbr: "TM", Full: "Turkmenistan" },
  { Abbr: "TC", Full: "Turks and Caicos Islands" },
  { Abbr: "TV", Full: "Tuvalu" },
  { Abbr: "UG", Full: "Uganda" },
  { Abbr: "UA", Full: "Ukraine" },
  { Abbr: "AE", Full: "United Arab Emirates" },
  { Abbr: "GB", Full: "United Kingdom" },
  { Abbr: "UM", Full: "United States Minor Outlying Islands" },
  { Abbr: "US", Full: "United States of America" },
  { Abbr: "UY", Full: "Uruguay" },
  { Abbr: "UZ", Full: "Uzbekistan" },
  { Abbr: "VU", Full: "Vanuatu" },
  { Abbr: "VE", Full: "Venezuela" },
  { Abbr: "VN", Full: "Viet Nam" },
  { Abbr: "VG", Full: "Virgin Islands (British)" },
  { Abbr: "VI", Full: "Virgin Islands (U.S.)" },
  { Abbr: "WF", Full: "Wallis and Futuna" },
  { Abbr: "EH", Full: "Western Sahara" },
  { Abbr: "YE", Full: "Yemen" },
  { Abbr: "ZM", Full: "Zambia" },
  { Abbr: "ZW", Full: "Zimbabwe" },
  { Abbr: "AX", Full: "Aland Islands" },
];

const CountryData = (Full = true) => {
    const result = [];
    CountryArray.forEach((item) => {
        result.push({
            value: item.Abbr,
            label: Full ? item.Full : item.Abbr
        });
    });
    return result;
}

class CountrySelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;
        
        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={CountryData(this.props.full).filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={CountryData(this.props.full)}
            >
            </Select>
        );
    }
};

export default CountrySelect;
