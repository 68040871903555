import React from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Card } from "react-bootstrap";
import PropTypes from "prop-types";

import Table from "../../../layout/table";
import { table as getInvoices } from "../../../actions/invoice";
import { format as formatDate } from "../../../utils/date";
import { currency } from "../../../utils/number";

import ConditionalButton from "../../../layout/conditional-button";

class Invoices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            size: 10,
            search: "",
            order: "number",
            index: 0,
            direction: "DESC",
            data: [],
            pages: 0,
            archived: 0,
            converted: 0,
            past: 0,
            active: 1
        };
    }

    componentDidMount = () => {
        setTimeout(() => {
            this.fetchData();
        }, 30);
    }


    fetchData = async () => {
        const __data = await this.props.getInvoices(this.state.page, this.state.size, this.state.order, this.state.direction);
        let __count = 0;

        if ((__data) && (__data.length || 0 > 0))
            __count = Math.ceil(Number(__data[0].count) / Number(this.state.size));

        this.setState({
            ...this.state,
            data: __data,
            pages: __count
        });
    }


    get headers() {
        return [
            {
                header: "Date",
                sortable: true,
                source: "invoiceGenerated",
                className: "text-muted text-small text-uppercase w-20",
                onRender: this.onDateFormat
            },
            {
                header: "#",
                sortable: true,
                source: "number",
                className: "text-muted text-small text-uppercase w-10"
            },
            {
                header: "Services",
                sortable: true,
                source: "currentCharges",
                className: "text-muted text-small text-uppercase w-20",
                onFormat: this.onCurrencyFormat
            },
            {
                header: "Ending Trust",
                sortable: true,
                source: "trustEnd",
                className: "text-muted text-small text-uppercase w-20",
                onFormat: this.onCurrencyFormat
            },
            {
                header: "Payment Req'd",
                sortable: true,
                source: "paymentRequired",
                className: "text-muted text-small text-uppercase w-20",
                onFormat: this.onCurrencyFormat
            },
            {
                header: "",
                sortable: false,
                source: "uuid",
                className: "text-muted text-small text-uppercase w-10",
                onRender: this.onDownloadFormat
            }
        ]
    }

    onDownloadFormat = (e, row) => {
        return <>
            <a
                target="_blank"
                role="button"
                onClick={(e) => { this.props.onDownload(row["uuid"]) }}
                className="badge rounded-pill bg-info"
                style={{ marginLeft: "5px", paddingLeft: "15px", paddingRight: "15px" }}>pdf</a>
        </>
    }

    onDateFormat = (e) => {
        return formatDate(e, false);
    }

    onCurrencyFormat = (e, row) => {
        return currency(Number(e));
    }


    onColumnClick = (e) => {
        const __array = this.headers;
        const __index = __array.findIndex((item) => {
            return item.header === e.header && item.source === e.source;
        });

        this.setState({
            ...this.state,
            index: __index,
            direction: this.state.index === __index && this.state.direction === "DESC" ? "ASC" : "DESC",
            order: __array[__index].source,
            page: 0
        }, this.fetchData);
    }

    onSearch = (e) => {
        this.setState({
            ...this.state,
            search: e
        }, this.fetchData);
    }

    onSize = (e) => {
        this.setState({
            ...this.state,
            page: 0,
            size: Number(e)
        }, this.fetchData);
    }

    onPageChange = (e) => {
        this.setState({
            ...this.state,
            page: Number(e)
        }, this.fetchData);
    }


    render() {
        return (
            <>
                <Card body>
                    <Col xs="12">
                        <h2 className="small-title">Invoices</h2>
                    </Col>
                    <Col xs="12">
                        <Table
                            headers={this.headers}
                            page={this.state.data}
                            sort={{ index: this.state.index, direction: this.state.direction }}
                            size={this.state.size}
                            index={this.state.page}
                            pages={this.state.pages}
                            onColumnClick={this.onColumnClick}
                            onPageChange={this.onPageChange}
                        />
                    </Col>
                </Card>
                
            </>
        )
    }
}

Invoices.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { getInvoices })(Invoices);