import api from "../utils/api";

import { LOGOUT } from "./types";


export const docx = (xml, templateUuid) => async (dispatch) => {
    try {
        const res = await api.post(`/renderer/transform/docx`, { xml, templateUuid }, { responseType: 'blob' });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const fields = (pdf) => async (dispatch) => {
    try {
        const res = await api.post("/renderer/prepared/fields", { pdf });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}

export const pdf = (xml, templateUuid) => async (dispatch) => {
    try {
        const res = await api.post(`/renderer/prepared/fill`, { xml, templateUuid }, { responseType: 'blob' });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const xsl = (xml, reportUuid) => async (dispatch) => {
    try {
        const res = await api.post(`/renderer/transform/pdf`, { xml, reportUuid });
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const named = (mask, xml) => async (dispatch) => {
    try {
        var xsl =
           `<xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">`+
           `<xsl:output method="text"/>`+
            `<xsl:template match="/data">${mask}</xsl:template>`+
            `</xsl:stylesheet>`;
        const res = await api.post("/renderer/transform", { xml, xsl });
        return res.data.result;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}
