import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Row, Col, Card } from "react-bootstrap";

import { format as formatDate } from  "../../utils/date";
import { JavaScripttoXML } from "../../utils/serialize";

import Events from "./events";
import Invoices from "./invoices";
import Files from "./files";

import { get as getInvoice } from "../../actions/invoice";
import { docx as generateDocx, pdf as generatePdf, xsl as generateXsl } from "../../actions/report";

class Summary extends React.Component {
    onInvoiceDownload = async (invoice) => {
        setTimeout(async () => {
            const __invoice = await this.props.getInvoice(invoice);
            
            const __fileName = `${__invoice.Company?.name?.length ? __invoice.Company?.name : __invoice.Person.lastName + ", " + __invoice.Person.firstName} ${formatDate(__invoice.invoiceGenerated)} Invoice ${__invoice.number}.pdf`;
            const _xml = JavaScripttoXML(__invoice);
            const _result = await this.props.generateXsl(_xml, "270f0523-4e61-11ed-a3e9-0efa9373c42b");

            const linkSource = `data:application/pdf;base64,${_result}`;
            const link = document.createElement("a");

            link.href = linkSource;
            link.setAttribute(
                'download',
                __fileName,
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }, 30);
    }

    render() {
        return (
            <>
                <Col md="8" className="float-right text-end"></Col>
                <Col xs="12" md="6" lg="4">
                    <Events history={this.props.history} />
                </Col>
                <Col xs="12" md="6" lg="8">
                    <Row>
                        <Col xs="12">
                            <Invoices onDownload={this.onInvoiceDownload} />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs="12">
                            <Card body className="mb-5">
                                <Files onSelect={this.onFileSelect} updated={this.state?.file?.updated}></Files>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </>
        )
    }
}

Summary.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { getInvoice, generateDocx, generatePdf, generateXsl  })(Summary);