import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";

import setAuthToken from "./utils/security/auth";
import api from "./utils/api";

import { LOGOUT } from "./actions/types";
import { loadUser } from "./actions/auth";
import { get as getLicenseeProfile } from "./actions/licensee";

import NotFound from "./views/errors/404";
import Login from "./views/login";
import Reset from "./views/reset";
import Forgot from "./views/forgot";
import Routes from "./routing/Routes";

import store from "./store";

const App = () => {

  useEffect(() => {

    store.dispatch(getLicenseeProfile(window.location.host));
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
      store.dispatch(loadUser());
    }

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router forceRefresh>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/reset/:token" component={Reset} />
          <Route exact path="/forgot" component={Forgot} />
          <Route component={Routes} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
