

export default function complexity ( password, elements )
{
    var result = 0;
    if ( /^(?=.*[^!@#$%^&*()_=+a-zA-Z0-9])/.test( password ) )
    {
        return {
            score: 0,
            feedback: "Invalid character in password"
        };
    }
    result += ( !elements.lower || /(?=[a-z])/.test( password ) ? 1 : 0);
    result += ( !elements.upper || /(?=[A-Z])/.test( password ) ? 1 : 0 );
    result += ( !elements.number || /(?=.*\d)/.test( password ) ? 1 : 0 );
    result += ( !elements.symbol || /(?=.*[!@#$%^&*()_=+])/.test( password ) ? 1 : 0 );
    return {
        score: result,        
        feedback: ( result < 4 ? "Password not complex enough" : "" )
    };
}