import api from "../utils/api";
import {
  GET_NOTIFICATIONS, LOGOUT
} from "./types";

export const get = () => async (dispatch) => {
    try {
        const res = await api.get(`/notifications/me`);

        dispatch({
            type: GET_NOTIFICATIONS,
            payload: res.data,
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const read = (id) => async (dispatch) => {
    try {
        const res = await api.put(`/notifications/me`, { uuid: id });
        if (res.data.success) dispatch(get());
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    } 
}
