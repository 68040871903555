import api from "../utils/api";

export const contents = ({ path }) => async (dispatch) => {
    try {
        const res = await api.get(`/files/me/directory?path=${path}`);
        return res.data;
    } catch (err) {
    }
}

export const update = ({ path, uuid, shares }) => async (dispatch) => {
    try {
        const res = await api.post(`/files/me/directory`, { uuid, path, shares });
        return res.data;
    } catch (err) {
    }
} 
