import React from "react";
import { Pagination } from 'react-bootstrap';
import CsLineIcons from "../../cs-line-icons";

class FeraPagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: this.props.index || 0
        }
    }

    onGotoPage = (pageNumber) => {
        this.setState({
            index: pageNumber
        }, this.afterGotoPage);
    }

    afterGotoPage = () => {
        if (this.props.onChange) this.props.onChange(this.state.index); 
    }
    
    componentDidUpdate = (oldProps) => {
        if (this.props.index !== oldProps.index) {
            const { index } = this.props;

            this.setState({
                index
            });
        }
    }

    get atEnd() {
        return this.state.index === (this.props.count - 1);
    }

    get atStart() {
        return this.state.index === 0;
    }

    render() {
        if (!this.props.count || this.props.count <= 1) return <></>;

        return (
            
            <Pagination className="justify-content-center mb-0 mt-3">
                <Pagination.First className="shadow" onClick={() => this.onGotoPage(0)} disabled={this.atStart}>
                    <CsLineIcons icon="arrow-double-left" />
                </Pagination.First>
                <Pagination.Prev className="shadow" disabled={this.atStart} onClick={() => this.onGotoPage(this.state.index-1)}>
                    <CsLineIcons icon="chevron-left" />
                </Pagination.Prev>
                {[...Array(this.props.count)].map((x, i) => (
                    <Pagination.Item key={`pagination${i}`} className="shadow" active={this.state.index === i} onClick={() => this.onGotoPage(i)}>
                        {i + 1}
                    </Pagination.Item>
                ))}
                <Pagination.Next className="shadow" onClick={() => this.onGotoPage(this.state.index+1)} disabled={this.atEnd}>
                    <CsLineIcons icon="chevron-right" />
                </Pagination.Next>
                <Pagination.Last className="shadow" onClick={() => this.onGotoPage(this.props.count - 1)} disabled={this.atEnd}>
                    <CsLineIcons icon="arrow-double-right" />
                </Pagination.Last>
            </Pagination>
  
            
        )
    }
}

export default FeraPagination;