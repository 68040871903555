import { combineReducers } from "redux";

import auth from "./auth";
import alert from "./alert";
import licensee from "./licensee";
import notification from "./notification";

export default combineReducers({
    auth,
    alert,
    licensee,
    notification
});
