import React from "react";
import { connect } from "react-redux";
import { Form, Col, Row } from "react-bootstrap";
import IntlTelInput from 'react-intl-tel-input';
import PropTypes from "prop-types";

import { deepEqual } from "../../utils/object";
import { calculate } from "../../utils/validation";

import GenerationsSelect from "../../layout/select/generation";
import Address from "../../layout/address";

import 'react-intl-tel-input/dist/main.css';
import 'react-datepicker/dist/react-datepicker.css';

class Personal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            generation: props.generation,
            Phone: {
                number: ""
            }
        };
    }

    componentDidUpdate = (oldProps) => {
        if (!deepEqual(this.props, oldProps)) {
            var __props_minus_fields = { ...this.props };
            var __old_minus_fields = { ...oldProps };
            delete __props_minus_fields.fields;
            delete __old_minus_fields.fields;
            delete __old_minus_fields.file;
            delete __props_minus_fields.file;

            if (!deepEqual(__props_minus_fields, __old_minus_fields)) {
                this.setState({
                    ...this.state,
                    ...this.props
                });
            } 
            if (this.props.fields.length !== oldProps.fields.length)
                this.sendOnChange();
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        }, this.sendOnChange)
    }

    onGenerationChange = (e) => {
        this.onChange({ target: { name: "generation", value: e.value } });
    }


    onAddressChange = (e) => {
        this.setState({
            ...this.state,
            Residence: {
                ...this.state.Residence,
                ...e
            }
        }, this.sendOnChange);
    }

    onPhoneChange = (isValid, newNumber, country, fullNumber, extension) => {

        this.setState({
            ...this.state,
            Phone: {
                ...this.state.Phone,
                country: country.iso2.toUpperCase(),
                number: fullNumber,
                valid: isValid,
            }
        }, this.sendOnChange)
    }

    sendOnChange = () => {
        if (this.props.onChange) {
            this.props.onChange({ ...this.state }, calculate(this.props.fields, "PROFILE", this.state));
        }
    }

    render() {
        return (
            <>
                <Row>
                    <Col lg="12">
                        <Row className="g-3">
                            <Col lg="12">
                                <h2 className="small-title">Personal Information</h2>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col lg="4">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="firstName" value={this.state.firstName || ""} onChange={this.onChange} />
                                    <Form.Label>FIRST NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="middleName" value={this.state.middleName || ""} onChange={this.onChange} />
                                    <Form.Label>MIDDLE NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="4">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="lastName" value={this.state.lastName || ""}  onChange={this.onChange} />
                                    <Form.Label>LAST NAME</Form.Label>
                                </div>
                            </Col>
                            <Col lg="2">
                                <div className="mb-3 top-label">
                                    <GenerationsSelect value={this.state.generation || ""} onChange={this.onGenerationChange} placeholder="" name="generation" />
                                    <Form.Label>SUFFIX</Form.Label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="g-3">
                            <Col lg="12">
                                <div className="mb-3 top-label">
                                    <Form.Control type="text" name="preferredName" value={this.state.preferredName || ""} onChange={this.onChange} />
                                    <Form.Label>PREFERRED NAME</Form.Label>
                                </div>
                            </Col>
                        </Row>
                
                    </Col>
                </Row>

                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">My Current Address</h2>
                    </Col>
                </Row>
                <Address {...this.state.Residence} onChange={ this.onAddressChange } />
                <Row className="g-3">
                    <Col lg="12">
                        <h2 className="small-title">My Preferred Phone #</h2>
                    </Col>
                </Row>
                <Row className="g-3">
                    <Col lg="12">
                        <div className="mb-3">
                            <IntlTelInput containerClassName="intl-tel-input" inputClassName="form-control" name="phone" id="phone" value={this.state.Phone?.number || ""} onPhoneNumberBlur={this.onPhoneChange} />
                        </div>
                    </Col>
                </Row>
            </>
        )
    }
}

Personal.propTypes = {
    fields: PropTypes.array,
};

const mapStateToProps = (state) => {
    return ({
        fields: state.fields.items
    });
};

export default connect(mapStateToProps)(Personal);

