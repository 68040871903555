import React from 'react';
import { connect } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

import CsLineIcons from '../../cs-line-icons';
import NotificationItem from "./item";
import { read as readNotifications, get as getNotifications } from '../../actions/notification';

class Notifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showing: props?.showing || false,
            items: []
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showing !== this.props?.showing || false) {
            this.setState({
                showing: this.props.showing
            });
        }

        if (prevProps.items !== this.props.items) {
            this.setState({
                items: this.props.items
            });
        }
    }

    MENU_NAME = "mnuNotifications";

    onToggle = (status, event) => {
        if (event?.stopPropagation) {
            event.preventDefault();
            event.stopPropagation();
        } else if (event?.originalEvent?.stopPropagation) {
            event.originalEvent.preventDefault();
            event.originalEvent.stopPropagation();
        } 
        this.setState({
            showing: !this.state.showing
        })
    };

    NotificationsDropdownToggle = React.memo(
        React.forwardRef(({ onClick, expanded = false }, ref) => (
            <a
                ref={ref}
                href="#/"
                className="notification-button"
                data-toggle="dropdown"
                aria-expanded={expanded}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClick(e);
                }}
            >
                <div className="position-relative d-inline-flex">
                    <div style={{color: "white"}}><CsLineIcons icon="bell" size="18" /></div>
                    <span className="position-absolute notification-dot rounded-xl" />
                </div>
            </a>
        ))
    );

    onRead = (e) => {
        var id = e.target?.attributes["data-id"]?.value;
        this.props.readNotifications(id);
    }

    NotificationsDropdownMenu = React.memo(
        React.forwardRef(({ style, className, labeledBy, items }, ref) => {
            return (
                <div ref={ref} style={style} className={`wide notification-dropdown scroll-out ${className}`} aria-labelledby={labeledBy}>
                    <OverlayScrollbarsComponent
                        options={{
                            scrollbars: { autoHide: 'leave', autoHideDelay: 600 },
                            overflowBehavior: { x: 'hidden', y: 'scroll' },
                        }}
                        className="scroll"
                    >
                        <ul className="list-unstyled border-last-none">
                            {items.map((item, idx) => 
                            (
                                <NotificationItem key={`notificationItem.${idx}`} onRead={this.onRead} id={item.uuid} style={item.style} title={item.title} description={item.description} />
                            )
                            )}
                        </ul>
                    </OverlayScrollbarsComponent>
                </div>
            );
        })
    );

    render() {
        if (this.state.items?.length === 0) return <></>;

        return (
            <Dropdown
                as="li"
                bsPrefix="list-inline-item"
                onToggle={this.onToggle}
                show={this.state.showing}
                align="end"
            >
                <Dropdown.Toggle as={this.NotificationsDropdownToggle} />
                <Dropdown.Menu
                    as={this.NotificationsDropdownMenu}
                    items={this.state.items}
                    popperConfig={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: { offset: () => [0, 7] }
                            },
                        ],
                    }}
                />
      
            </Dropdown>
        )
    }
}

Notifications.propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object,
    items: PropTypes.array
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth,
        profile: state.licensee.profile,
        notification: state.notification, 
        items: state.notification.items
    });
};

export default connect(mapStateToProps, { readNotifications, getNotifications })(Notifications);
