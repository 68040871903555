import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

class Sizer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            size: props.size || 10
        }
    }

    get options() {
        return [
            5, 10, 25, 100
        ]
    }

    onChange = (e) => {
        this.setState({
            size: e
        }, this.postChange)
    }

    postChange = () => {
        if (this.props.onChange) this.props.onChange(this.state.size);
    }


    render() {
        return (
            <OverlayTrigger placement="top" delay={{ show: 1000, hide: 0 }} overlay={<Tooltip>Item Count</Tooltip>}>
                <Dropdown className="d-inline-block" align="end">
                    <Dropdown.Toggle variant="foreground-alternate" className="shadow">
                        {this.state.size} Items
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        className="shadow dropdown-menu-end"
                        popperConfig={{
                            modifiers: [
                                {
                                    name: 'computeStyles',
                                    options: {
                                        gpuAcceleration: false,
                                    },
                                },
                            ],
                        }}
                    >
                        {this.options.map((size) => (
                            <Dropdown.Item key={`size.${size}`} active={size === this.state.size} onClick={() => this.onChange(size)}>
                                {size} Items
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </OverlayTrigger>
        );
    }
}

export default Sizer;