import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Navigation from "./navigation";
import Sidebar from "./sidebar";
import Footer from "./footer";

const Layout = ({ children }) => {

  useEffect(() => {
    document.body.classList.add('h-100');
    const root = document.getElementById('root');
    if (root) {
      root.classList.add('h-100');
    }
    return () => {
      document.body.classList.remove('h-100');
      if (root) {
        root.classList.remove('h-100');
      }
    };
  }, []);

    return (
        <>
            <Navigation />
            <main>
                <Container>
                    <Row className="h-100">
                        <Sidebar />
                        <Col className="h-100" id="contentArea">
                            {children}
                        </Col>
                    </Row>
                </Container>
            </main>
            <Footer />
        </>
    );
};
export default Layout;
