import React, { Fragment } from 'react';
import spinner from './spinner.gif';

const Spinner = () => (
  <Fragment>
      <div style={{ zIndex: '10000', height: '100vh', width: '100vw', backgroundColor: 'white' }}>
            <img
            src={spinner}
            style={{  textAlign: 'center', width: '200px', margin: 'auto', display: 'block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'  }}
            alt="Loading..."
            />
    </div>
  </Fragment>
);

export default Spinner;