import React from "react";
import { Dropdown } from "react-bootstrap";

class DropdownItem extends React.Component
{
    onClick = (e) => {
        this.props.onClick(e, this.props);
    }

    render() {
        return (
            <Dropdown.Item href="#" onClick={this.onClick}>{this.props.caption}</Dropdown.Item>
        );
    }
}

export default DropdownItem;