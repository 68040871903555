import api from "../utils/api";

import { GET_INVOICES, LOGOUT } from "./types";

export const table = (page, size, order, direction) => async (dispatch) => {
    try {
        const res = await api.get(`/clients/invoices/me/p/${page}/s/${size}/o/${order}/${direction}`);

        dispatch({
            type: GET_INVOICES,
            payload: res.data,
        });

        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
};

export const get = (uuid) => async (dispatch) => {
    try {
        const res = await api.get(`/clients/invoices/me/i/${uuid}`);
        return res?.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}