import React from "react";
import { Table, Row, Col } from "react-bootstrap";
import Thead from "./thead";
import Colgroup from "./colgroup";
import Tbody from "./tbody";
import Searcher from "./searcher";
import PageSizer from "./sizer";
import Pagination from "./pagination";

class FeraTable extends React.Component {
    render() {
        return (
            <>
                {this.props.searchable || this.props.sizable ?
                    <Row className="mb-3 mt-3">
                        {this.props.searchable ?
                            <Col sm="12" md="5" lg="3" xxl="2">
                                <div className="d-inline-block float-md-start me-1 mb-1 mb-md-0 search-input-container w-100 shadow bg-foreground">
                                    <Searcher onChange={this.props.onSearch} search={this.props.search} />
                                </div>
                            </Col>
                            : ``}
                        {this.props.sizable ?
                            <Col sm="12" md="7" lg="9" xxl="10" className="text-end">
                                <div className="d-inline-block">
                                    <PageSizer onChange={this.props.onSize} size={this.props.size} />
                                </div>
                            </Col>
                            : ``}
                    </Row>
                    : ``}
                {this.props.filter ?
                    {...this.props.filter}
                : ``}
                <Row>
                    <Col xs="12">
                        <Table className={this.props.className || 'react-table boxed'} role="table">
                            <Colgroup headers={this.props.headers} />
                            <Thead headers={this.props.headers} onColumnClick={this.props.onColumnClick} sort={this.props.sort} />
                            <Tbody headers={this.props.headers} page={this.props.page} />
                        </Table>
                    </Col>
                    <Col xs="12">
                        <Pagination index={this.props.index} count={this.props.pages} onChange={ this.props.onPageChange } />
                    </Col>
                </Row>
            </>
        )
    }
}

export default FeraTable;