import React from "react";
import CsLineIcons from "../../cs-line-icons";

class Searcher extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.search
        }
    }

    onChange = (e) => {
        this.setState({
            value: e.target.value
        }, this.postChange);
    };

    onClear = (e) => {
        this.setState({
            value: ""
        }, this.postChange);     
    }

    postChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state.value);
        }
    }

    render() {
        return (
            <>
                <input
                    className="form-control datatable-search"
                    value={this.state.value || ''}
                    onChange={this.onChange}
                    placeholder="Search"
                />
                {this.state.value && this.state.value.length > 0 ? (
                    <span
                        className="search-delete-icon"
                        onClick={this.onClear}
                    >
                        <CsLineIcons icon="close" />
                    </span>
                ) : (
                    <span className="search-magnifier-icon pe-none">
                        <CsLineIcons icon="search" />
                    </span>
                )}
            </>
        )
    }
}

export default Searcher;