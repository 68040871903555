import { v4 as uuidv4 }  from 'uuid';
import { SET_ALERTS, REM_ALERT, REM_ALERTS } from './types';

export const send = (msg, alertType) => dispatch => {
  dispatch(pack([msg], alertType));
};

export const pack = (msgs, alertType) => dispatch => {
  const __messages = [];
  for (var i = 0; i < msgs.length; i++) {
    __messages.push({
      message: msgs[i],
      type: alertType,
      id: uuidv4()
    });
  };
  dispatch({
    type: SET_ALERTS,
    payload: __messages
  });
}

export const remove = (id) => dispatch => {
  dispatch({ type: REM_ALERT, payload: id });
}

export const empty = (id) => dispatch => {
  dispatch({ type: REM_ALERTS });
}