import { SET_ALERTS, SET_ALERT, REM_ALERT, REM_ALERTS } from '../actions/types';

const initialState = [];

function alertReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ALERTS:
      return [...payload];
    case SET_ALERT:
      return [...state, [payload]];
    case REM_ALERT:
      return state.filter((alert) => alert.id !== payload);
    case REM_ALERTS:
      return [];
    default:
      return state;
  }
}

export default alertReducer;
