import api from "../api";

export async function send ( from, recipient, subject, html, attachments = [] )
{
    const resEmail = await api.post( "/renderer/email", { from, recipient, subject, html, attachments } );
    return resEmail.data;
}

export async function build(xml, name) {
    let res = await api.get( `/emails/n/${ name }` );
    let __data = Array.isArray( res.data ) ? res.data[ 0 ] : res.data;
    let dataXslBody = __data.html;
    var dataXslSubject = `
    <xsl:stylesheet version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">
      <xsl:output method="text"/>
      <xsl:template match="/data">${ __data.subject }</xsl:template>
    </xsl:stylesheet>
    `;
    const resSubject = await api.post( "/renderer/transform", {
        xml: xml,
        xsl: dataXslSubject,
    } );

    const resBody = await api.post( "/renderer/transform", {
        xml: xml,
        xsl: dataXslBody,
    } );

    return {
        from: __data.from,
        subject: resSubject.data.result,
        html: resBody.data.result
    };
}

