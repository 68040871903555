import React from "react";

class FeraTbodyTd extends React.Component {
    get cell() {
        return (this.props.header.onFormat) ? this.props.header.onFormat(this.props.item[this.props.header.source], this.props.item) : this.props.item[this.props.header.source];
    }

    get rendered() {
        return (this.props.header.onRender) ? this.props.header.onRender(this.props.item[this.props.header.source], this.props.item) : this.props.item[this.props.header.source];
    }

    render() {
        return (
            (!this.props.header.onRender) ?
                <td
                    colSpan={this.props.header.colspan || 1}
                    role="cell"
                    className={`${this.props.header.dataClassName || "list-item-heading body"}`}
                    dangerouslySetInnerHTML={{ __html: this.cell }}
                />
                :
                <td
                    colSpan={this.props.header.colspan || 1}
                    role="cell"
                    className={`${this.props.header.dataClassName || "list-item-heading body"}`} >
                    {this.rendered}
                </td>
        )
    }
}

export default FeraTbodyTd;