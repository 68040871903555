import React from "react";
import { deepEqual } from "../../../utils/object";
import Select from 'react-select';

export function exists(value) {
    return StatesArray.filter(obj => { return obj.Abbr === value });
}

export const StatesArray = [
  { Abbr: "AK", Full: "Alaska" },
  { Abbr: "AL", Full: "Alabama" },
  { Abbr: "AR", Full: "Arkansas" },
  { Abbr: "AS", Full: "Samoa" },
  { Abbr: "AZ", Full: "Arizona" },
  { Abbr: "CA", Full: "California" },
  { Abbr: "CO", Full: "Colorado" },
  { Abbr: "CT", Full: "Connecticut" },
  { Abbr: "DC", Full: "DC" },
  { Abbr: "DE", Full: "Deleware" },
  { Abbr: "FL", Full: "Florida" },
  { Abbr: "GA", Full: "Georgia" },
  { Abbr: "GU", Full: "Guam" },
  { Abbr: "HI", Full: "Hawaii" },
  { Abbr: "IA", Full: "Iowa" },
  { Abbr: "ID", Full: "Idaho" },
  { Abbr: "IL", Full: "Illinois" },
  { Abbr: "IN", Full: "Indiana" },
  { Abbr: "KS", Full: "Kansas" },
  { Abbr: "KY", Full: "Kentucky" },
  { Abbr: "LA", Full: "Louisiana" },
  { Abbr: "MA", Full: "Massachusetts" },
  { Abbr: "MD", Full: "Maryland" },
  { Abbr: "ME", Full: "Maine" },
  { Abbr: "MI", Full: "Michigan" },
  { Abbr: "MN", Full: "Minnesota" },
  { Abbr: "MO", Full: "Missouri" },
  { Abbr: "MP", Full: "N. Marianas" },
  { Abbr: "MS", Full: "Mississippi" },
  { Abbr: "MT", Full: "Montana" },
  { Abbr: "NC", Full: "N. Carolina" },
  { Abbr: "ND", Full: "N. Dakota" },
  { Abbr: "NE", Full: "Nebraska" },
  { Abbr: "NH", Full: "New Hampshire" },
  { Abbr: "NJ", Full: "New Jersey" },
  { Abbr: "NM", Full: "New Mexico" },
  { Abbr: "NV", Full: "Nevada" },
  { Abbr: "NY", Full: "New York" },
  { Abbr: "OH", Full: "Ohio" },
  { Abbr: "OK", Full: "Oklahoma" },
  { Abbr: "OR", Full: "Oregon" },
  { Abbr: "PA", Full: "Pennsylvania" },
  { Abbr: "PR", Full: "Puerto Rico" },
  { Abbr: "RI", Full: "Rhode Island" },
  { Abbr: "SC", Full: "S. Carolina" },
  { Abbr: "SD", Full: "S. Dakota" },
  { Abbr: "TN", Full: "Tennessee" },
  { Abbr: "TX", Full: "Texas" },
  { Abbr: "UM", Full: "Outlying Islands" },
  { Abbr: "UT", Full: "Utah" },
  { Abbr: "VA", Full: "Virginia" },
  { Abbr: "VI", Full: "Virgin Islands" },
  { Abbr: "VT", Full: "Vermont" },
  { Abbr: "WA", Full: "Washington" },
  { Abbr: "WI", Full: "Wisconsin" },
  { Abbr: "WV", Full: "W. Virginia" },
  { Abbr: "WY", Full: "Wyoming" },
];

const StatesData = (Full = true) => {
    const result = [];
    StatesArray.forEach((item) => {
        result.push({
            value: item.Abbr,
            label: Full ? item.Full : item.Abbr
        });
    });
    return result;
}

class StateSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props
        };
    }

    componentDidUpdate = (oldProps) => {
        if (deepEqual(oldProps, this.props)) return;
        if (deepEqual(this.state, this.props)) return;

        this.setState({
            ...this.props
        });
    }

    onChange = (e) => {
        if (this.state.value !== e.value) {
            this.setState({ value: e.value }, () => { this.state.onChange(e) });
        }
    }

    render() {
        return (
            <Select
                id={this.state.id}
                value={StatesData(this.props.full).filter(option => option.value === this.state.value)}
                className={this.state.className}
                name={this.props.name}
                onChange={this.onChange}
                classNamePrefix="react-select"
                placeholder=""
                options={StatesData(this.props.full)}
            >
            </Select>
        );
    }
};

export default StateSelect;
