import React from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { format as formatDate } from "../../../utils/date";
import CsLineIcons from "../../../cs-line-icons";


class Item extends React.Component
{

    doOpen = () => {
        if (this.props.onOpen) this.props.onOpen({...this.props})
    }
    
    doChecked = () => {
        if (this.props.onChecked) this.props.onChecked({...this.props})
    }
    
    render() {
        const __item = {};
        
        if (this.props.extension === "__dir") {
            __item.icon = "folder";
            __item.path = this.props.path.replace("S3:", "").split("\\");
            __item.path.pop();
            __item.name = __item.path.pop();
        } else {
            __item.icon = "file-empty";
            __item.name = this.props.name;
        }

        

        return (
            <Col xs="12" xxl="6">
                <Card className="mb-5 p-3" role="button" onDoubleClick={this.doOpen} >
                    <Row>
                        <Col md="2">
                            <CsLineIcons size="48" icon={__item.icon} />
                        </Col>
                        <Col md="9">
                            <Card.Text style={{ fontSize: "1.2rem", marginBottom: "0px" }}>{__item.name}</Card.Text>
                            <Card.Text style={{ color: "gray", fontSize: ".8rem" }}>Last updated {formatDate(this.props.updatedAt)}</Card.Text>
                        </Col>
                        <Col md="1">
                            <Form.Check className="form-check float-end mt-1" type="checkbox" checked={this.props.checked} onChange={this.doChecked} />
                        </Col>
                    </Row>
                </Card>
            </Col>
        );
    }   
}

export default Item;