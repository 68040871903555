import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal, Row, Col, Form } from 'react-bootstrap';
import { v4 as uuidv4 }  from 'uuid';

import ConditionalButton from "../conditional-button";
import { isGuid } from "../../utils/string";

import DatePickerEx from "../date-picker";


class ExModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.propsToState;
    }

    get propsToState() {
        let __working = {
            ...this.props
        }

        return {
            name: __working.name
        }
        
    }

    onHideModal = (e) => {
        this.onCancel(e)
    }

    postChange = () => {
        
    }

    componentDidUpdate = (old) => {
        if (this.props.show && !old.show) {
            this.setState(this.propsToState)
        }
    }

    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }


    onCancel = async (e) => {
        this.props.onCancel(e);
    }

    onOk = (e) => {
        this.props.onOk({
            uuid: isGuid(this.props.uuid) ? this.props.uuid : uuidv4(),
            name: this.state.name
        });
    }

    get canSave() {
        return  this.state.name?.length > 1;
    }

    render() {
        if (!this.props.show) {
            return <></>
        }
        return (
            <Modal size="lg" backdrop="static" keyboard={false} show={this.props.show} onHide={this.onHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title id="staticBackdropLabel">{this.props.title || `New Folder`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="g-3 pb-3 mt-1">
                        <Col xs="12">
                            <div className="top-label">
                                <Form.Control type="text" name="name" value={this.state.name || ""} onChange={this.onChange} />
                                <Form.Label>NAME</Form.Label>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ConditionalButton enabledVariant="outline-primary" display enabled onEnabledClick={this.onCancel} icon="error-hexagon">cancel</ConditionalButton>
                    <ConditionalButton enabledVariant="outline-primary" display enabled={this.canSave} onEnabledClick={this.onOk} icon="save">ok</ConditionalButton>
                </Modal.Footer>
            </Modal>
        );
    }
}

ExModal.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return ({
        auth: state.auth
    });
};

export default connect(mapStateToProps)(ExModal);