import validate from "validate.js";

function calculate (fields, subject, startFrom) {
        var validity = [];

        fields.forEach(field => {
            const {
                uuid,
                name,
                required,
                requiredMessage,
                minLength,
                minLengthMessage,
                maxLength,
                maxLengthMessage,
                minValue,
                minValueMessage,
                maxValue,
                maxValueMessage,
                regex,
                regexMessage,
                when
            } = field;
            const path = name.split(".");
            if (path[0] !== subject) return;
            var i = 1;
            var __control_value = startFrom;
            var __control_name = "";
            while (i < path.length) {
                __control_name = path[i];
                __control_value = __control_value[__control_name];
                i++;
                if(!__control_value) break;
            }

            var __do_process = true;
            if (when) {
                var __when_name = "";
                var __when_copy = when;

                const __when_path = when.split("$$");
                const __when_names = [];
                i = 1;
                while (i < __when_path.length) {
                    __when_names.push(__when_path[i]);
                    i += 2;
                }

                i = 0;
                while (i < __when_names.length) {
                    var __when_value = startFrom;
                    var __when_dotted = __when_names[i].split(".");
                    var j = 0;
                    while (j < __when_dotted.length) {
                        __when_name = __when_dotted[j];
                        __when_value = __when_value[__when_name];
                        if ((__when_value || undefined) === undefined) {
                            __when_value = "false";
                            break;
                        }
                        j++;
                    }
                    __when_copy = String(__when_copy).replace(`$$${__when_names[i]}$$`, __when_value);
                    i++;
                }
                __do_process = eval(__when_copy);
            }

            if (!__do_process) return;

            const __constraints = { };
            if (required) __constraints.presence = { message: requiredMessage };
            if (regex) __constraints.format = { pattern: regex, message: regexMessage ||requiredMessage };
            if (minValue) __constraints.numericality = { greaterThanOrEqualTo: eval(minValue), message: minValueMessage || requiredMessage };
            if (maxValue) __constraints.numericality = { ...__constraints.numericality, lessThanOrEqualTo: eval(maxValue), message: maxValueMessage || minValueMessage || requiredMessage };
            if (minLength) __constraints.length = { minimum: minLength, tooShort: minLengthMessage || requiredMessage };
            if (maxLength) __constraints.length = { ...__constraints.length, maximum: maxLength, tooLong: maxLengthMessage || requiredMessage };

            var __result = validate.single( (__control_value !== null && __constraints.numericality) ? Number(__control_value) : __control_value, __constraints);
            if (__result) validity.push({ name: __control_name, errors: __result });
        });
        return validity;
}
    
export { calculate };