import React from "react";
import Td from "./tbody.td";

class FeraTbodyTr extends React.Component {
    render() {
        return (
            <tr>
                {this.props.headers.map((item, index) => {
                    return (
                        <Td key={`tbody.tr.td.${index}`} item={this.props.item} header={item} />
                    );
                })}
            </tr>
        )
    }
}
export default FeraTbodyTr;