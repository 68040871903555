import React from "react";
import { Route, Switch } from "react-router-dom";

import NotFound from "../views/errors/404";
import Dashboard from "../views/dashboard";
import PrivateRoute from "./PrivateRoutes";

const Routes = ( props ) =>
{
  return (
    <>
      <Switch>
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;
