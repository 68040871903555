import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CsLineIcons from '../../../cs-line-icons';
import LayoutFullPage from '../../../layout/FullPage';
import Header from '../../../layout/header';
import { Toaster } from 'react-hot-toast';

class _404Page extends React.Component {

    get rightSide() {
        return (
            <div className="sw-lg-80 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                <div className="sw-lg-60 px-5">
                    <div className="sh-11">
                        <NavLink to="/">
                            <div className="logo-default" style={{ backgroundImage: `url(/img/l/logo/${this.props.profile?.smallLogo})` }}  />
                        </NavLink>
                    </div>
                    <div className="mb-5">
                        <h2 className="cta-1 mb-0 text-primary">Ooops, it looks like an error!</h2>
                        <h2 className="display-2 text-primary">404 Not Found</h2>
                    </div>
                    <div className="mb-5">
                        <p className="h6">It looks like the page you are looking for is not available.</p>
                        <p className="h6">
                            If you think that is a mistake, please <NavLink to="/contact">contact</NavLink> us.
                        </p>
                    </div>
                    <div>
                        <NavLink to="/" className="btn btn-icon btn-icon-start btn-primary">
                            <CsLineIcons icon="arrow-left" /> <span>Back to Home</span>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        
        return (
            <>
                <Toaster position='bottom-right' reverseOrder={false} />
                <Header title='404 Not Found' description='404 Page Not Found' />
                <LayoutFullPage right={this.rightSide} background={this.props.profile?.backgroundImage}  />
            </>
        );
    }
}


_404Page.propTypes = {
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps)(_404Page);

