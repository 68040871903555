import React from "react";

class FeraColGroupCol extends React.Component
{

    render() {
        return (
            <col
                key={this.props.item.key}
                span={this.props.item.colspan || 1}
                className={`${this.props.item.className}`}
                data-column={this.props.item}
                data-index={this.props.index}
            />
        )
    }
}

export default FeraColGroupCol;