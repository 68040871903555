export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGOUT = "LOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADED = "USER_LOADED";
export const VERIFY_TOKEN_FAILED = "VERIFY_TOKEN_FAILED";
export const VERIFY_TOKEN_SUCCESS = "VERIFY_TOKEN_SUCCESS";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAILED = "RESET_FAILED";
export const PASSWORD_CHANGE = "PASSWORD_CHANGE";
export const SET_LOADED = "SET_LOADED";
export const SET_LOADING = "SET_LOADING";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const GET_PROFILE_DATA = "GET_PROFILE_DATA";

export const REM_ALERTS = "REM_ALERTS";
export const REM_ALERT = "REM_ALERT";
export const SET_ALERTS = "SET_ALERTS";
export const SET_ALERT = "SET_ALERT";

export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENT = "GET_EVENT";
export const SET_EVENT = "SET_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";

export const GET_CLIENT = "GET_CLIENT";
export const GET_CLIENTS = "GET_CLIENTS";

export const GET_INVOICES = "GET_INVOICES";

export const GET_REPORTS = "GET_REPORTS";
export const GET_REPORT = "GET_REPORT";

export const GET_FILES = "GET_FILES";
export const ADD_FILE = "ADD_FILE";