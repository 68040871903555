
const data = [
  {
    id: "home",
    icon: "home",
    caption: "Home",
    to: "/dashboard",
    title: "Client Dashboard",
    description: " Client facing dashboard",
    display: [],
    permissions: ["CAN.LOGIN"],
    contains: {
      indent: 0,
      items: [
        {
          id: "my_profile",
          icon: "content",
          caption: "My Profile",
          to: "/dashboard/profile/me",
          title: "My Profile",
          description: "Biographical information about you",
          display: ["Sidebar"],
          permissions: ["CAN.LOGIN"],
        },
        {
          id: "client_billing",
          icon: "content",
          caption: "Billing",
          to: "/dashboard/billing",
          title: "Billing",
          description: "Billing information",
          display: ["Sidebar", "Mobile"],
          permissions: ["VIEW.BILLING"],
          contains: {
            indent: 1,
            hide: true,
            items:
              [
                {
                  id: "edit_billing",
                  icon: "content",
                  caption: "This Billing",
                  to: "/dashboard/billing/:id",
                  title: "View Billing",
                  display: [],
                  permissions: ["VIEW.BILLING"],
                }
              ]
          }
        },
        {
          id: "client_discovery",
          icon: "content",
          caption: "Discovery",
          to: "/dashboard/discovery",
          title: "Discovery",
          description: "Discovery",
          display: ["Sidebar", "Mobile"],
          permissions: ["VIEW.DISCOVERY"],
          contains: {
            indent: 1,
            hide: true,
            items:
              [
                {
                  id: "client_discovery_interrogatories",
                  icon: "content",
                  caption: "Interrogatories",
                  to: "/dashboard/discovery/interrogatories",
                  title: "Interrogatory Responses",
                  display: [],
                  permissions: ["EDIT.DISCOVERY"],
                },
                {
                  id: "client_discovery_admissions",
                  icon: "content",
                  caption: "Admissions",
                  to: "/dashboard/discovery/admissions",
                  title: "Admission Responses",
                  display: [],
                  permissions: ["EDIT.DISCOVERY"],
                },
                {
                  id: "client_discovery_rpds",
                  icon: "content",
                  caption: "Documents",
                  to: "/dashboard/discovery/production",
                  title: "Document Responses",
                  display: [],
                  permissions: ["EDIT.DISCOVERY"],
                },
                {
                  id: "client_discovery_opposing",
                  icon: "content",
                  caption: "Opposing Party",
                  to: "/dashboard/discovery/opposing",
                  title: "Opposing Party Responses",
                  display: [],
                  permissions: ["EDIT.DISCOVERY"],
                },
              ]
          }
        },
        {
          id: "files",
          icon: "content",
          caption: "Files",
          to: "/dashboard/files",
          title: "Files",
          description: "Files",
          display: ["Sidebar"],
          permissions: ["VIEW.FILES"],
        }
      ]
    }
  }
];

export default data;

