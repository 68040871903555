import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";

import { Toaster, toast } from 'react-hot-toast';
import { deepEqual } from "../../utils/object";
import { empty as removeAlert } from "../../actions/alert";

class Footer extends React.Component {

    send_alert(alert) {
        if (alert.type === "success") {
            toast.success(alert.message);
        } else if (alert.type === "error") {
            toast.error(alert.message);
        } else {
            toast(alert.message);
        }
        this.props.removeAlert(alert.id);
    }

    send_alerts(alerts) {
        alerts.map((alert) => this.send_alert(alert));
    }

    shouldComponentUpdate(nextProps) {
        return !deepEqual(nextProps, this.props) || false;
    }

    componentDidUpdate() {
        setTimeout(() => {
            this.send_alerts(this.props?.alerts || []);
        }, 30);
    }

    render() {
        return (
            <>
                <footer>
                    <div className="footer-content">
                        <Container>
                            <Row>
                                <Col xs="12" sm="6">
                                    <p className="mb-0 text-muted text-medium">Feradel, Ltd.</p>
                                </Col>
                                <Col sm="6" className="d-none d-sm-block">
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </footer>
                <Toaster position='bottom-right' reverseOrder={false} />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps, { removeAlert })(Footer);