import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { permitted, userPermissions } from "../../utils/security";
import SidebarMenuItems from "./items"; 

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      submenu: false,
    };
  }

  get menuItems() {
    return this.props.pages[0].contains.items;
  }

  filteredList = (menuItems) => {
    var result = [];

    menuItems.forEach(
      function (item) {
        var __add_it = !item.permissions;        

        if (__add_it) {
          result.push(item);
          return;
        }

        if (permitted(userPermissions(this.props.auth), item.permissions)) {
          result.push(item);
          return;
        }
      }.bind(this)
    );
    return result;
  };

  onShowSubmenu = (e, item) => {
    this.setState({
      item: item.id,
      submenu: !this.state.submenu,
    });
    return false;
  };

  onClick = (e, item) => {
    this.props.history.push(item.to);
  };

  render() {
    if (!this.props.auth) return <></>;
    return (
      <Col xs="auto" className="side-menu-container">
        <ul className="sw-25 side-menu mb-0 primary" id="menuSide">
          <SidebarMenuItems items={this.props.pages[0].contains.items} />
        </ul>
      </Col>
    )
  }
}


export default Sidebar;
