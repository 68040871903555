import React from "react";
import CsLineIcons from '../../cs-line-icons';

class Item extends React.Component {
    get image() {
        let __image = "file-empty";
  
        switch (this.props.style) {
            case "UR":
                __image = "flag";
                break;
            case "LO":
                __image = "arrow-bottom";
                break;
            case "3D":
                __image = "form-check";
                break;
            default:
                break;
        }
        return __image;
    }

    get color() {
        let __color = "black";
  
        switch (this.props.style) {
            case "UR":
                __color = "red";
                break;
            case "LO":
                __color = "gray";
                break;
            case "3D":
                __color = "blue";
                break;
            default:
                break;
        }

        return __color;
    }

    render() {
        
        return (
            <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                <div style={{ color: this.color, paddingRight: "5px" }}><CsLineIcons icon={this.image} size="15" /></div>
                <div className="align-self-center">
                    <a href="#" onClick={this.props.onRead} data-id={this.props.id}>
                        {this.props.description}
                    </a>
                </div>
            </li>
        );
    }
}

export default Item;