import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import ReCAPTCHA from "react-google-recaptcha";
import { Toaster, toast } from 'react-hot-toast';

import CsLineIcons from '../../cs-line-icons';
import { sendReset as reset } from "../../actions/auth";
import { isEmail } from '../../utils/regex';

import LayoutFullPage from '../../layout/FullPage';
import Header from '../../layout/header';
import ConditionalButton from '../../layout/conditional-button';
class Forgot extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: this.email || "",
            valid: false
        }
    }

    onChange = (e) =>
        this.setState({
            [e.target.name]: e.target.value,
        });
    
        
    onCaptchaChange = (value) => {
        this.setState({
            ...this.state,
            valid: true
        })
    }
    
    onCaptchaErrored = (value) => {

    }

    onCaptchaExpired = (value) => {

    }

    onSubmit = async (e) => {
        e.preventDefault();
        await this.props.reset(this.state.email, this.props.profile?.uuid);
        toast.success("Request has been made! Please check your email.");
        this.setState({
            email: ""
        });
    };

    componentDidUpdate(prevProps) {

    }
    
    get email() {
        const queryParams = queryString.parse(window.location.search)
        return queryParams["e"];
    }

    get leftSide() {
        return (
            <div className="min-h-100 d-flex align-items-center">
                <div className="w-100 w-lg-75 w-xxl-50">
                    <div>
                        <div className="mb-5">
                            <h1 className="display-3 text-white">{this.props.profile?.tagLine005}</h1>
                        </div>
                        <p className="h6 text-white lh-1-5 mb-5">
                            {this.props.profile?.tagLine006}
                        </p>
                        <div className="mb-5">
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    get rightSide() {
        return (
            <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
                <div className="sw-lg-50 px-5">
                    <div className="sh-11">
                        <NavLink to="/">
                            <div className="logo-default" style={{ backgroundImage: `url(/img/l/logo/${this.props.profile?.smallLogo})` }} />
                        </NavLink>
                    </div>
                    <div className="mb-5">
                        <h2 className="cta-1 mb-0 text-primary">Password trouble?</h2>
                        <h2 className="cta-1 text-primary">let's change it now!</h2>
                    </div>
                    <div className="mb-5">
                        <p className="h6">Please enter the email address that is associated with your account. If found, we will send you a link to change your password.</p>
                    </div>
                    <div>
                        <Row>
                            <Col sm="12">
                                <div className="mb-3 filled form-group tooltip-end-top">
                                    <CsLineIcons icon="email" />
                                    <Form.Control
                                        className="form-control"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}
                                        placeholder='Email'
                                        required />
                                </div>
                            </Col><Col sm="6">
                                <ReCAPTCHA
                                    sitekey="6LdHM18pAAAAAImpnGt9SpZRs6HLrKcZKyPuuJoh"
                                    onChange={this.onCaptchaChange}
                                    onExpired={this.onCaptchaExpired}
                                    onErrored={this.onCaptchaErrored}
                                    size="compact"
                                /></Col><Col sm="6">
                                <ConditionalButton enabledVariant="outline-primary" tooltip="Send reset email" display={true} enabled={this.state.valid && isEmail(this.state.email)} onEnabledClick={this.onSubmit}>Reset Password</ConditionalButton>
                            </Col></Row>
                    </div>
                </div>
            </div>
        );
    };


    render() {
        return (
            <>
                <Toaster position='bottom-right' reverseOrder={false} />
                <Header title='Forgot' description='Use this page if you forgot your password. It will send the password to your email, if it is found.' />
                <LayoutFullPage left={this.leftSide} right={this.rightSide} background={this.props.profile?.backgroundImage} />
            </>
        );
    }
};

Forgot.propTypes = {
    reset: PropTypes.func.isRequired,
    profile: PropTypes.object
};

const mapStateToProps = (state) => {
    return ({
        profile: state.licensee.profile
    });
};

export default connect(mapStateToProps, { reset })(Forgot);