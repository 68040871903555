
import React from "react";
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CsLineIcons from '../../cs-line-icons';

class ConditionalButton extends React.Component {

    get defaultButtonClass() {
        return "btn-icon btn-icon-start ms-0 ms-sm-1 w-100 w-md-auto";
    }

    get defaultPlacement() {
        return "bottom";
    }

    get button() {
        return (
            <Button variant={this.props.enabled ? this.props.enabledVariant : this.props.disabledVariant} className={this.props.className || this.defaultButtonClass} onClick={this.props.enabled ? this.props.onEnabledClick : this.props.onDisabledClick}>
                <CsLineIcons icon={this.props.icon} /> <span> {this.props.children}</span>
            </Button>
        );
    }

    get overlay() {
        return (
            <OverlayTrigger delay={{ show: 10, hide: 0 }} overlay={<Tooltip>{this.props.tooltip}</Tooltip>} placement={this.props.oveerlayPlacement || this.defaultPlacement}>
                {this.button}
            </OverlayTrigger>
        );
    }
    render() {
        if (this.props.display) {
            return (this.props.enabled && this.props.tooltip) ?
                    this.overlay
                    :
                    this.button
        }
        return null;
    }
}

export default ConditionalButton;
