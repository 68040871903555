import React from "react";
import Tr from "./thead.tr";
class FeraThead extends React.Component {
    render() {
        return (
            <thead>
                <Tr headers={this.props.headers} onColumnClick={this.props.onColumnClick} sort={this.props.sort} />
            </thead>
        );
    }
}

export default FeraThead;