import {
  GET_NOTIFICATIONS,
  SET_LOADING
} from "../actions/types";

const initialState = {
  items: [],
  loading: false,
};

function notificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case GET_NOTIFICATIONS:
      return {
        ...state,
        items: payload,
        loading: false,
      };
    default:
      return state;
  }
}

export default notificationReducer;
