function userPermissions(auth) {
  return auth?.user?.permissions || [];
}

function permitted(permissions, searchFor) {
  let __includes = false;
  let __permissionNames = searchFor;

  if (!Array.isArray(__permissionNames)) {
    __permissionNames = [searchFor];
  }

  __permissionNames.forEach((element) => {
    __includes |= permissions.includes(element);
    if (__includes) return true;
  });
  return __includes;
}

export { userPermissions, permitted };
