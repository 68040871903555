
import api from "../utils/api";
import { GET_EVENTS, LOGOUT } from "./types";

export const get = (dateFrom, dateTo, eventUuid) => async (dispatch) => {
    try {
        // /df/:dateFrom/dt/:dateTo/a/:assignedToUuid/e/:eventUuid
        const res = await api.get(`/events/me/df/${dateFrom}/dt/${dateTo}/e/${eventUuid}`);

        if (res?.data) {
            dispatch({
                type: GET_EVENTS,
                payload: res.data
            });
        }
        return res.data;
    } catch (err) {
        dispatch({
            type: LOGOUT
        });
    }
}
